
import './ViewerPage.scss'

import { useHMSStore, selectIsConnectedToRoom, selectHLSState } from '@100mslive/react-sdk';

function ViewerPage() {
    const isConnected = useHMSStore(selectIsConnectedToRoom);
    const SState = useHMSStore(selectHLSState);



    return (
        <div className="wrapper">


        </div>
    );
}

export default ViewerPage;
