import BeatButton from "../Button/Button";
import "./Footer.scss";

function Footer() {



    return (
        <div className="footer">


        </div>
    )
}

export default Footer;