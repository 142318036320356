import { Link } from "react-router-dom";
import "./HeaderRow.scss";
import SignOutBeat from "../SignOut/SignOut";



const HeaderRow = () => {
    return (
        <header className="header__row">
            <h1 className="header__row__title">BEATSTREAM</h1>
            <div className="header__row__links">
                <Link className="header__row__links-profile" to="/">ROOMS</Link>
                <Link className="header__row__links-profile" to="/profile">PROFILE</Link>
                <SignOutBeat />
            </div>
        </header>
    );
};

export default HeaderRow;
