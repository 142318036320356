import { Link } from "react-router-dom";
import "./Button.scss";


const BeatButton = ({ title, onClick, children }) => {
    return (
        <div className="beat__button-button">
            {children}
            <button className="beat__button-bg" onClick={() => {
                onClick()
            }} >  {title}</button>
        </div>);
}

export default BeatButton;
