import { useState } from "react"
import Message from "../Message/Message"
import { selectHMSMessages, useHMSActions, useHMSStore } from "@100mslive/react-sdk"
import { selectPeers } from "@100mslive/react-sdk"
import './ChatNdParticipants.scss'
import data from "@emoji-mart/data";
import EmojiPicker from "../EmojiPicker/EmojiPicker"

// Defining the ChatNdParticipants component
function ChatNdParticipants() {

  // State for managing the selected tab (chat or participants)
  const [selectedOption, setSelectedOption] = useState('chat')

  // State for managing the chat message input
  const [message, setMessage] = useState('')

  // Using hooks from the @100ms to access messages and actions
  const messages = useHMSStore(selectHMSMessages)
  const hmsActions = useHMSActions()

  // Fetching the list of peers (participants) in the chat
  const peers = useHMSStore(selectPeers)

  // Function to handle message sending
  const handleSubmit = (e) => {
    e.preventDefault();
    hmsActions.sendBroadcastMessage(message)
    setMessage('')
  }

  // Rendering the component
  return (
    <div className='rightBox'>
      <div className='rightBox__head'>
        {/* Tabs for switching between chat and participants view */}
        <span
          onClick={() => setSelectedOption('chat')}
          className={selectedOption === 'chat' ? 'selected' : ''}
        >
          CHAT
        </span>
        <span
          className={selectedOption === 'participants' ? 'selected' : ''}
          onClick={() => setSelectedOption('participants')}
        >
          PARTICIPANTS
        </span>
      </div>
      <div className="rightBox__optionView">
        {/* Conditionally rendering the chat or participants view */}
        {selectedOption === 'chat' &&
          <>
            <div className="rightBox__chats">
              {/* Displaying messages */}
              {messages.map((msg) => (
                <Message key={msg.id} message={msg} />
              ))}
            </div>
            <form className="send__message" onSubmit={handleSubmit}>
              {/* Input field for typing a message */}
              <div className="send__message__emoji">
                <EmojiPicker
                  onSelect={(e) => setMessage(message + e)}
                  data={data}
                  previewPosition="none"
                  skinPosition="search"
                />
                <input
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  className=""
                  placeholder="Write your message"
                />
                {/* Emoji picker component */}


              </div>
            </form>
          </>
        }
        {selectedOption === 'participants' &&
          <div className="rightBox__participants">
            {/* Displaying list of participants */}
            {peers.map((peer) => (
              <div className='rightBox__participant'>
                {peer.name}
                <p>{peer.roleName}</p>
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  )
}

export default ChatNdParticipants;
