import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './ProfilePage.scss';
import { useUser } from '@clerk/clerk-react';
import Header from '../../components/Header/Header';

const ProfilePage = () => {
    // const { isLoaded, userId, sessionId, getToken } = useAuth();
    const { isLoaded, isSignedIn, user } = useUser();


    const [profile, setProfile] = useState({ username: '', email: '', avatar: null });
    const [avatarPreview, setAvatarPreview] = useState('');
    const [croppedImageUrl, setCroppedImageUrl] = useState('');
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [uploadMessage, setUploadMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [crop, setCrop] = useState({ aspect: 1 });
    const fileInputRef = useRef(null);
    const imageRef = useRef(null);
    const backendApiURL = process.env.REACT_APP_BACKEND_API_URL;

    useEffect(() => {
        axios.get(`${backendApiURL}/me`,)
            .then(response => {
                const { user } = response.data;
                setProfile({ ...user, avatar: null });
                setAvatarPreview(user.avatar_url || 'path-to-your-placeholder-image.jpg');
            })
            .catch(error => console.error('Error fetching profile data', error));
    }, []);

    const onImageLoaded = image => {
        imageRef.current = image;
    };

    const onCropComplete = crop => {
        makeClientCrop(crop);
    };

    const makeClientCrop = async crop => {
        if (imageRef.current && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imageRef.current,
                crop,
                'newFile.jpeg'
            );
            setCroppedImageUrl(croppedImageUrl);
        }
    };

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(croppedImageUrl);
                resolve(window.URL.createObjectURL(blob));
            }, 'image/jpeg');
        });
    };

    const handleFileChange = async e => {
        const file = e.target.files[0];
        if (!file) return;

        // Image Upload Validation: Size under 5MB and JPEG/PNG format
        if (file.size > 5242880 || !file.type.match('image/jpeg|image/png')) {
            setUploadMessage('File must be less than 5MB and in JPEG or PNG format.');
            return;
        }
        // const res = await user.setProfileImage(file);
        // console.log('handle file', res);
        try {


            console.log('upload file');
            const res = await user.setProfileImage(file);
        } catch (e) {
            console.log('err ', e);
        }

    };

    const handleAvatarClick = () => {
        fileInputRef.current.click();
    };

    const handleChange = e => {
        setProfile({ ...profile, [e.target.name]: e.target.value });
        setUnsavedChanges(true);
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append('username', profile.username);
        formData.append('email', profile.email);

        if (croppedImageUrl) {
            formData.append('avatar', croppedImageUrl);
        }

        try {
            const response = await axios.post(`${backendApiURL}/profile`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true
            });
            console.log(response.data);
            setUnsavedChanges(false);
            setUploadMessage('Profile updated successfully.');
        } catch (error) {
            console.error('Error updating profile', error);
            setUploadMessage('Failed to update profile. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    if (!isLoaded || !user) {
        return null;
    }
    //setProfileImage

    return (
        <>
            <Header />
            <div className="profile">
                <h1 className="profile__title">PROFILE</h1>
                <form onSubmit={handleSubmit} className="profile__form">
                    <div className="profile__image-container">
                        <img
                            src={user.imageUrl}
                            alt="Profile Avatar"
                            className="profile__avatar"
                            onClick={handleAvatarClick}
                        />
                        {/* <input

                        type="file"
                        name="avatar"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        className="profile__input profile__input--file"
                        style={{ display: 'block' }}
                    /> */}
                        {avatarPreview && (
                            <ReactCrop
                                src={avatarPreview}
                                crop={crop}
                                onImageLoaded={onImageLoaded}
                                onComplete={onCropComplete}
                                onChange={newCrop => setCrop(newCrop)}
                            >

                            </ReactCrop>
                        )}
                    </div>
                    <input
                        type="text"
                        name="username"
                        value={user.fullName}
                        onChange={handleChange}
                        className="profile__input profile__input--text"
                        placeholder="Username"
                    />
                    <input
                        type="email"
                        name="email"
                        value={user.primaryEmailAddress.emailAddress}
                        onChange={handleChange}
                        className="profile__input profile__input--email"
                        placeholder="Email Address"
                    />
                    <button
                        type="submit"
                        className="profile__button profile__button--save"
                        disabled={!unsavedChanges}
                    >
                        Save Changes
                    </button>
                    {uploadMessage && <div className="profile__message">{uploadMessage}</div>}
                    {isLoading && <div className="profile__loading">Loading...</div>}
                </form>
            </div>
        </>
    );
};

export default ProfilePage;
