import { useHMSStore, selectHLSState } from '@100mslive/react-sdk';
import Hls from 'hls.js';
import { useEffect, useRef } from 'react';
import './HlsView.scss'

// Defining the HlsView component
function HlsView() {
    // Using hooks to get HLS state from the @100mslive/react-sdk
    const hlsState = useHMSStore(selectHLSState);

    // Extracting the HLS URL
    const hlsUrl = hlsState.variants[0]?.url;

    // Ref for the video element
    const videoRef = useRef(null);
    console.log("hlsState ", hlsState);

    // useEffect hook to setup the HLS streaming
    useEffect(() => {
        async function setupHLS() {
            if (videoRef.current && hlsState.running && hlsUrl) {
                // Checking if the browser supports HLS natively
                const browserHasNativeHLSSupport = videoRef.current.canPlayType('application/vnd.apple.mpegurl');

                // Using Hls.js for HLS streaming if supported
                if (Hls.isSupported()) {
                    let hls = new Hls();
                    hls.loadSource(hlsUrl);
                    hls.attachMedia(videoRef.current);


                    // Handling errors in HLS streaming
                    hls.on(Hls.Events.ERROR, function (event, data) {
                        if (data.fatal) {
                            switch (data.type) {
                                case Hls.ErrorTypes.NETWORK_ERROR:
                                    console.error("Network error encountered", data);
                                    break;
                                case Hls.ErrorTypes.MEDIA_ERROR:
                                    console.error("Media error encountered", data);
                                    break;
                                default:
                                    console.error("Unhandled HLS.js error", data);
                                    break;
                            }
                        }
                    });
                }
                // Using native HLS support if available
                else if (browserHasNativeHLSSupport) {
                    videoRef.current.src = hlsUrl;
                }
            }
        }

        setupHLS();

        // Cleanup function for the useEffect
        return () => {
            if (videoRef.current && hlsUrl && Hls.isSupported()) {
                let hls = new Hls();
                hls.destroy();
            }
        };
    }, [hlsUrl, hlsState.running, videoRef]);

    // Rendering a message if no broadcast is running
    if (!hlsState.running)
        return <><div className='broadcaster'>NO BROADCASTER PRESENT</div></>

    // Rendering the video element
    return <video ref={videoRef} autoPlay muted></video>;
}

// Exporting the component for use in other parts of the application
export default HlsView;
