// Importing necessary components and hooks
import VideoTile from "../VideoTile/VideoTile";
import HlsView from "../HlsView/HlsView";
import { selectLocalPeer, selectPeers, useHMSStore } from "@100mslive/react-sdk";
import './Stream.scss'

// Defining the Stream component
function Stream() {
  // Using hooks to get the list of peers and the local peer's details
  const peers = useHMSStore(selectPeers)
  const localPeer = useHMSStore(selectLocalPeer)
  console.log({ localPeer, peers });

  // Rendering the component
  return (
    <div className="stream">
      {/* Displaying video tiles for each broadcaster if the local user is a broadcaster */}
      {localPeer.roleName === 'broadcaster' &&
        peers.filter((peer) => peer.roleName === 'broadcaster')
          .map((peer) => (
            <VideoTile key={peer.id} peer={peer} peers={peers} />
          ))
      }

      {/* Displaying HLS view if the local user is a viewer */}
      {localPeer.roleName === 'viewer-realtime' &&
        <HlsView />
      }
    </div>
  )
}

// Exporting the component for use in other parts of the application
export default Stream;
