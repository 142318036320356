import React, { useState } from 'react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { Popover, Button } from "@mui/material";

// Defining the EmojiPicker component
function EmojiPicker({ onSelect }) {
    // State to manage the visibility of the emoji picker
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

    // State to manage the anchor element for the popover
    const [anchorEl, setAnchorEl] = useState(null);

    // Function to handle the emoji button click
    const handleEmojiClick = (event) => {
        setAnchorEl(event.currentTarget);
        setEmojiPickerOpen(true);
    };

    // Function to close the emoji picker
    const handleEmojiClose = () => {
        setEmojiPickerOpen(false);
    };

    // Function to handle emoji selection
    const handleSelect = (emj) => {
        console.log("Handle Select inside");
        if (onSelect) {
            onSelect(emj.native);  // Calling the onSelect prop function with the selected emoji
        }
        setEmojiPickerOpen(false); // Closing the picker after selection
    }

    // Rendering the component
    return (
        <div>
            {/* Button to trigger the emoji picker */}
            <Button onClick={handleEmojiClick}>
                😃
            </Button>

            {/* Popover for the emoji picker */}
            <Popover
                open={emojiPickerOpen}
                anchorEl={anchorEl}
                onClose={handleEmojiClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {/* Button to close the emoji picker inside the popover */}
                <Button onClick={() => { setEmojiPickerOpen(false) }}>Close</Button>
                {/* Emoji picker component */}
                <Picker data={data} onEmojiSelect={handleSelect} />
            </Popover>
        </div>
    );
}


export default EmojiPicker;
