import axios from 'axios';
import BroadcastList from '../../components/BroadcasterList/BroadcasterList';
import './RoomPage.scss'
import { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';

const backendApiURL = process.env.REACT_APP_BACKEND_API_URL

function RoomPage() {

    const [room, setRoom] = useState();
    const [error, setError] = useState();
    const [rooms, setRooms] = useState();
    const [roomsSearch, setRoomsSearch] = useState();

    useEffect(() => {
        getstream();
    }, [])

    async function getstream() {
        const response = await axios.get(`${backendApiURL}/getrooms`,);
        setRooms(response.data);
        setRoomsSearch(response.data);
    }


    async function onCreateRoomClick() {
        const createRoom = await axios.post(`${backendApiURL}/createRoom`, { room });
        getstream();
    }

    function onChangeRoomName(e) {
        const roomName = e.target.value;
        if (roomName.length == 0) {
            setError('');
            setRoomsSearch(rooms || []);

            return;
        }
        if (rooms && rooms.length) {
            setRoomsSearch(rooms.filter(item => item.name.toUpperCase().includes(roomName.toUpperCase())))
        }
        if (!/^[a-zA-Z0-9,-:_]+$/.test(roomName)) {
            setError('Room name should have only a-zA-Z0-9,-:_ characters ');
        } else if (roomName.length >= 15) {
            setError('Room name should not have more than 15 characters');
            return;
        }
        else {
            setError('');
        }
        setRoom(e.target.value);
    }

    return (
        <>
            <Header />
            <div className="room__page__list">
                <button className='room__page__list-button' onClick={onCreateRoomClick} disabled={!room || !!error}>CREATE ROOM</button>
                <input className='room__page__list-input' onChange={onChangeRoomName} placeholder={'ENTER ROOM NAME'}></input>
                <BroadcastList rooms={roomsSearch} onRefresh={getstream} />
                {error && <div className='text__error'>{error}</div>}
            </div>
        </>
    );
}

export default RoomPage;
