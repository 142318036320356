import { useVideo, useHMSStore, selectHLSState } from "@100mslive/react-sdk";
import './VideoTile.scss'
import { useState, useEffect } from "react";

// Defining the VideoTile component
function VideoTile({ peer, peers }) {
  const hlsState = useHMSStore(selectHLSState);
  const [ref, setRef] = useState();

  // Using the useVideo hook from @100mslive/react-sdk to manage video streaming
  const { videoRef } = useVideo({
    trackId: peer.videoTrack // Using the video track ID from the peer object
  })
  console.log('videoRef ', videoRef.prototype, hlsState.running, ref,);


  useEffect(() => {
    if (hlsState.running) {
      setRef(videoRef);
    } else {
      setRef(null);
    }

  }, [hlsState.running, videoRef]);

  // Function to calculate the number of broadcasters in the peers array
  const numberOfBroadCasters = () => {
    // Filtering peers to find those with a 'broadcaster' role
    const broadcasters = peers.filter((peer) => {
      return peer.roleName === 'broadcaster'
    })
    return broadcasters.length
  }

  // Rendering the component
  return (
    <video
      ref={videoRef} // Referencing the video element for the useVideo hook
      className={numberOfBroadCasters() >= 2 ? 'video' : ''} // Applying a CSS class conditionally
      autoPlay // Setting the video to autoplay
      muted // Muting the video by default
      playsInline // Ensuring the video plays inline on all platforms, including iOS
    />
  )
}


export default VideoTile;
