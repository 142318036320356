import { useEffect, useState } from 'react';
import { selectIsConnectedToRoom, useHMSActions, useHMSStore } from '@100mslive/react-sdk';
import "./JoinRoomSelected.scss";
import Header from '../../components/Header/Header';


import { useLocation, useParams } from 'react-router-dom';

import Room from '../../pages/Room/Room';
import { CircularProgress } from '@mui/material';

function JoinRoomSelected(props) {

  const ENDPOINT = process.env.REACT_APP_TOKEN_ENDPOINT;
  const isConnected = useHMSStore(selectIsConnectedToRoom)

  console.log(isConnected);

  const ROOM_BROADCASTER_CODE = process.env.REACT_APP_ROOM_BROADCASTER_CODE;

  const hmsActions = useHMSActions();
  const [username, setUsername] = useState('');
  const [selectedRole, setSelectedRole] = useState('select-role');

  const [loading, setLoading] = useState(false)

  const [roomName, setRoomName] = useState()

  const [authToken, setAuthToken] = useState()

  console.log("RENDER JON ROOM SELECTED")
  const params = useParams();
  const query = useLocation().search
  useEffect(() => {


    const urlSearchParams = new URLSearchParams(query)

    if (urlSearchParams.get("roomName"))
      setRoomName(urlSearchParams.get("roomName"))
    else
      setRoomName(params.id)
  }, [query])
  const backendApiURL = process.env.REACT_APP_BACKEND_API_URL;

  useEffect(() => {
    if (!authToken) return;
    console.log('joining ');
    hmsActions.join({
      userName: username,
      authToken: authToken,
    });
    return () => {
      hmsActions.leave()
    }
  }, [authToken])

  const handleSubmit = async (e) => {


    console.log('selectedRole', selectedRole);
    if (selectedRole == 'select-role') {
      e.preventDefault();
      return;
    }
    e.preventDefault();
    setLoading(true)
    try {

      const response = await fetch(`${ENDPOINT}api/token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id: `${Date.now()}`,
          role: selectedRole,
          room_id: params.id,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(`data.token`, data.token);
      setAuthToken(data.token);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };


  if (isConnected) {

    return <>

      <Room roomName={roomName} roomId={params.id} />

    </>
  }

  return (
    <>
      <Header />

      <div className="join__room__root">
        <h1 className='join__room__root__title'>JOIN: {roomName}</h1>
        <form className="join__room" onSubmit={handleSubmit}>
          <label className='label_room-name'>NAME:</label>
          <input
            className='join__room__w'
            type="text"
            required
            placeholder="Enter name"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label className='label_room'>ROLE:</label>
          <select
            className='join__room__w'
            required
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            <option value="select-role">Select Role</option>
            <option value="broadcaster">BROADCASTER</option>
            <option value="viewer-realtime">VIEWER</option>
          </select>
          <button className='join__room__w__button' type="submit">{loading ? <CircularProgress /> : "Join"}</button>
        </form>
      </div>
    </>
  );
}

export default JoinRoomSelected;
