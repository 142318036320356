import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import './BroadcastListItem.scss'

// Reading backend API URL from environment variables
const backendApiURL = process.env.REACT_APP_BACKEND_API_URL

// Defining the BroadcastListItem component
function BroadcastListItem({ peer, onRefresh }) {

    // useState hook for managing the edit state
    const [edit, setEdit] = useState(false);

    // useState hook for managing the room name
    const [roomName, setRoomName] = useState(peer.name);


    async function disableRoomClick() {

        const response = await axios.post(`${backendApiURL}/disableRoom`, { roomid: peer.id },)
        console.log(response);
        onRefresh();

    }
    // Function to update the room name
    async function updateRoomName() {


        // Making an API request to update the room
        const response = await axios.post(`${backendApiURL}/updateRoom`, { roomName, roomId: peer.id },);

        // Updating the edit state to false
        setEdit(false);

    }

    // Conditional rendering based on the edit state
    let content;
    if (edit) {
        content = <>
            <button className="button__container-join" onClick={updateRoomName}>{edit ? 'Update' : 'join'}</button>
        </>
    } else {
        content = <div>
            <Link className="button__container" to={`/rooms/${peer.id}?roomName=${roomName}`} state={
                {
                    param: roomName
                }
            }>
                <button className="button__container-join">JOIN</button>
            </Link>
            {<button className="button__container-input" onClick={disableRoomClick}>DISABLE</button>}
        </div>
    }

    let contentInput;
    if (edit) {
        contentInput = <>
            <input onChange={(e) => {
                setRoomName(e.target.value)
            }} value={roomName} />
        </>
    } else {
        contentInput = <>
            {roomName}
        </>
    }

    return (<div className="room__list__item">
        <div className="" key={peer.id} onClick={() => {
            console.log(' set room ');
            if (edit) return;
            setEdit(true);
        }
        } > {contentInput}</div>
        {content}
    </div>)
}

export default BroadcastListItem;
