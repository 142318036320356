// Room.js
import React, { useState } from 'react';
import axios from 'axios';
import Stream from '../../components/Stream/Stream';
import ChatNdParticipants from '../../components/ChatNdParticipants/ChatNdParticipants';
import Controls from '../../components/Controls/Controls';
import './Room.scss';
import StreamListUrl from '../../components/StreamListUrl/StreamListUrl';
import HeaderRow from '../../components/HeaderRow/HeaderRow';

const backendApiURL = process.env.REACT_APP_BACKEND_API_URL;

function Room({ roomName, roomId }) {
  const [recordings, setRecordings] = useState([]);

  async function onRecordingFinish() {
    console.log('onRecordingFinish');
    listRecordings();
  }

  async function listRecordings() {
    try {
      const response = await axios.post(`${backendApiURL}/listRecordings`, { roomId },);
      console.log('recording ', response.data);
      setRecordings(response.data);
    } catch (error) {
      console.error('Error fetching recordings:', error);
    }
  }

  async function onDownload(assetId) {
    try {
      const response = await axios.get(`${backendApiURL}/urlRecordings`, {
        params: { asset_id: assetId }
      },);

      if (response.status === 200 && response.data?.url) {
        window.open(response.data.url);
      }
    } catch (error) {
      console.error('Error during download:', error);
    }
  }

  return (
    <>
      <HeaderRow />
      <div className='room__stream-name'>{roomName}</div>
      <div className='room'>
        <div className='room__stream-space'>

          <Stream />
          <Controls roomId={roomId} onRecordingFinished={onRecordingFinish} />
          {recordings.map(item => (
            <StreamListUrl
              key={item.id}
              assetId={item.id}
              createdAt={item.created_at}
              onDownload={onDownload}
            />
          ))}
        </div>
        <ChatNdParticipants />
      </div>
    </>
  );
}

export default Room;
