// Importing necessary React hooks and styles
import React, { useEffect, useState } from 'react';
import './FallingCode.scss';

// Defining the FallingCode component
const FallingCode = () => {
    // State to store an array of code blocks
    const [codeBlocks, setCodeBlocks] = useState([]);

    // useEffect hook to initialize the code blocks on component mount
    useEffect(() => {
        // Function to create code block elements with random styles
        const createCodeBlocks = () => {
            const blocks = [];
            for (let i = 0; i < 50; i++) {
                // Generate pastel color
                const pastelColor = `rgb(${128 + Math.random() * 127}, ${128 + Math.random() * 127}, ${128 + Math.random() * 127})`;
        
                const style = {
                    left: `${Math.random() * 100}%`, // Random horizontal position
                    animationDelay: `${Math.random() * 5}s`, // Random delay for animation
                    backgroundColor: pastelColor, // Pastel color
                };
        
                blocks.push(<div key={i} className="code-illustration" style={style}></div>);
            }
            return blocks;
        };

        // Setting the created blocks to the state
        setCodeBlocks(createCodeBlocks());
    }, []); // Empty dependency array to ensure this runs only once on mount

    // Rendering the component
    return (
        <div className="background">
            {codeBlocks} {/* Rendering the array of code blocks */}
        </div>
    );
};

// Exporting the component for use in other parts of the application
export default FallingCode;
