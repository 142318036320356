import { Link } from "react-router-dom";
import "./Header.scss";
import SignOutBeat from "../SignOut/SignOut";



const Header = () => {
    return (
        <header className="header">
            <h1 className="header__title">BEATSTREAM</h1>
            <div className="header__links">
                <Link className="header__links-profile" to="/">ROOMS</Link>
                <Link className="header__links-profile" to="/profile">PROFILE</Link>
                <SignOutBeat />
            </div>
        </header>
    );
};

export default Header;
