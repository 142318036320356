import { selectLocalPeer, useHMSStore } from '@100mslive/react-sdk'
import './Message.scss'

// Defining the Message component
function Message({ message }) {
  // Using a hook to get the local peer's details
  const localPeer = useHMSStore(selectLocalPeer)

  // Rendering the message component
  return (
    <div
      // Applying different class names based on whether the message is sent by the local user
      className={`message ${message.senderUserId === localPeer.customerUserId && 'myMessage'}`}
    >
      {/* Displaying the sender's name */}
      <span>{message.senderName}</span>

      {/* Displaying the message text */}
      <p>{message.message}</p>
    </div>
  )
}


export default Message;
