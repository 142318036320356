import React, { useEffect, useState } from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import FallingCode from './components/FallingCode/FallingCode'
import RoomPage from './pages/RoomPage/RoomPage';
import ViewerPage from './pages/ViewerPage/ViewerPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import Header from './components/Header/Header';
import AuthModal from './components/AuthModal/AuthModal';
import axios from 'axios';
import JoinRoomSelected from './pages/JoinRoomSelected/JoinRoomSelected';
import Room from './pages/Room/Room';

import { ThemeProvider, createTheme } from '@mui/material';

import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  SignIn,
  SignUp,
  UserButton,
  useClerk
} from "@clerk/clerk-react";
import Footer from './components/Footer/Footer';

const backendApiURL = process.env.REACT_APP_BACKEND_API_URL
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;



function SignOutBeat() {
  const { signOut } = useClerk();
  const navigate = useNavigate();

  return <button onClick={async () => {
    await signOut();
    navigate('/sign-in/')
  }}>Sign out</button>

}

function App() {
  const [initialized, setInitialized] = useState(false)
  const [user, setUser] = useState(null)


  function onLoginError(params) {
    console.log('onLoginError ', params);
  }

  return (

    <BrowserRouter>
      <ThemeProvider theme={createTheme()}>
        <ClerkProvider publishableKey={clerkPubKey}>



          <FallingCode />

          {
            <Routes>
              <Route
                path="/sign-in/*"
                element={<div>
                  <Header />
                  <SignIn routing="path" path="/sign-in" />
                </div>}
              />
              <Route
                path="/sign-out/"
                element={
                  <SignOutBeat />
                }
              />
              <Route
                path="/sign-up/*"
                element={<SignUp routing="path" path="/sign-up" afterSignUpUrl={'/'} />}
              />

              <Route
                path="/*"
                element={
                  <div>
                    <SignedIn>
                      <Routes>
                        <Route path='/rooms/:id/' element={<JoinRoomSelected />} />
                        <Route path='/' element={<RoomPage />} />
                        <Route path='/viewer' element={<ViewerPage />} />
                        <Route path='/profile' element={<ProfilePage />} />
                        <Route path='/stream' element={<Room />} />
                      </Routes>


                    </SignedIn>

                    <SignedOut>

                      <Navigate to='/sign-in' />
                    </SignedOut>

                  </div>
                }
              />

            </Routes>}
          <Footer />

        </ClerkProvider>

      </ThemeProvider>
    </BrowserRouter >
  );
}

export default App;



