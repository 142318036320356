import {
  VideocamOutlined, VideocamOffOutlined, MicNoneOutlined, MicOffOutlined,
  LogoutOutlined, PodcastsOutlined, StopCircleOutlined
} from '@mui/icons-material';
import { IconButton, Button, CircularProgress, TextField, Box } from '@mui/material';
import { selectHLSState, useHMSActions, useHMSStore } from '@100mslive/react-sdk';
import { selectIsLocalAudioEnabled, selectIsLocalVideoEnabled, selectLocalPeer } from '@100mslive/react-sdk';
import './Controls.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RecordIcon } from '@100mslive/react-icons';
import BeatButton from '../Button/Button';

const backendApiURL = process.env.REACT_APP_BACKEND_API_URL

// Defining the Controls component
function Controls({ roomId, onRecordingFinished }) {
  // Hooks from @100mslive/react-sdk to interact with the video conference
  const hmsActions = useHMSActions();
  const hlsState = useHMSStore(selectHLSState);
  const audioEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const videoEnabled = useHMSStore(selectIsLocalVideoEnabled);
  const localPeer = useHMSStore(selectLocalPeer);
  const isBroadcaster = localPeer.roleName === 'broadcaster'
  const [isRecording, setIsRecording] = useState(false)
  const [isStreaming, setIsStreaming] = useState(false)
  // console.log('isBroadcaster ', isBroadcaster, localPeer);

  // State for managing if a stream state change is in progress
  const [streamStateChangeInProgress, setStreamStateChangeInProgress] = useState();

  // Hook to navigate to different routes
  const navigate = useNavigate();

  useEffect(() => {
    onRecordingFinished();
  }, [])

  // Effect hook to reset stream state change progress when HLS state changes
  useEffect(() => {
    setStreamStateChangeInProgress(false);

  }, [hlsState.running]);

  // Function to start HLS streaming
  const startHLSStreaming = async () => {
    console.log('startHLSStreaming');
    try {
      setStreamStateChangeInProgress(true);
      setIsStreaming(true);
      await hmsActions.startHLSStreaming();
    } catch (err) {
      alert(`Failed to start HLS: ${err}`);
    }
  };

  // Function to stop HLS streaming
  const stopHLSStreaming = async () => {
    try {
      setStreamStateChangeInProgress(false);
      setIsStreaming(false);
      await hmsActions.stopHLSStreaming();
      onRecordingFinished();
    } catch (err) {
      alert(`Failed to stop HLS: ${err}`);
    }
  };

  // Function to toggle audio
  const toggleAudio = async () => {
    await hmsActions.setLocalAudioEnabled(!audioEnabled);
  };

  // Function to toggle video
  const toggleVideo = async () => {
    await hmsActions.setLocalVideoEnabled(!videoEnabled);
  };

  // Function to leave the room
  const leaveRoom = async () => {
    try {
      if (localPeer.roleName === 'broadcaster' && hlsState.running) {
        await hmsActions.stopHLSStreaming();
      }
      await hmsActions.leave();
    } finally {
      navigate("/");
    }
  };

  const customButtonClasses = {
    root: 'mui-custom-button'
  };

  const customIconClasses = {
    root: 'mui-custom-icon'
  };



  async function onRecordingClick(enableRecording) {
    console.log('start click ');
    setStreamStateChangeInProgress(true);

    const response = await axios.post(`${backendApiURL}/handleRecording`, { roomId: roomId, enable: enableRecording },);
    if (response.status == 200 || response.status == 409) {
      if (isRecording) {
        onRecordingFinished();
      }
      setIsRecording(!isRecording);
    }
    setStreamStateChangeInProgress(false);
    console.log('start recording ', response);
  }

  console.log('streamStateChangeInProgress ', streamStateChangeInProgress, hlsState.running);
  // Rendering the control buttons and their functionalities
  return (
    <div className='controls'>
      {localPeer.roleName === 'broadcaster'
        ? <>
          {/* Toggle buttons for audio and video */}
          <IconButton className='controls__mic' onClick={toggleAudio}>
            {audioEnabled
              ? <MicNoneOutlined className='controls__mic' />
              : <MicOffOutlined className='controls__mic' />
            }
          </IconButton >
          <IconButton onClick={toggleVideo}>
            {videoEnabled
              ? <VideocamOutlined className='controls__mic' />
              : <VideocamOffOutlined className='controls__mic' />
            }
          </IconButton>
          {/* Button to leave the room */}
          <BeatButton
            // classes={customButtonClasses}
            // variant="contained"
            // disableElevation
            // className='leave'
            title={' LEAVE ROOM'}
            onClick={() => {
              leaveRoom();
            }}
          >
            <LogoutOutlined classes={customButtonClasses} sx={{ color: '#E58BE0' }} />
          </BeatButton>
          {!isStreaming && isBroadcaster && <BeatButton
            // classes={customButtonClasses}
            title={isRecording ? 'STOP RECORDING' : 'START RECORDING'}
            // variant="contained"
            // disableElevation
            onClick={() => { onRecordingClick(!isRecording) }} ><RecordIcon className='controls__button__live'></RecordIcon>
            {streamStateChangeInProgress && <CircularProgress sx={{ mr: 1 }} size={20} />}
          </BeatButton>}

          {!isRecording && !isStreaming && <Box className='text__label'>OR</Box>}
          {/* Buttons to start/stop HLS streaming */}
          {hlsState.running
            ? <BeatButton
              title={' STOP STREAMING'}
              // sx={{
              //   color: "red"
              // }}
              // classes={customButtonClasses}
              // variant="contained"
              // disableElevation
              className='mui-custom-button'
              onClick={stopHLSStreaming}
            >
              {streamStateChangeInProgress ? <CircularProgress sx={{ mr: 1 }} size={20} /> : <StopCircleOutlined className='controls__button__live' />}
            </BeatButton >
            : !isRecording &&
            <div>
              <BeatButton
                // classes={customButtonClasses}
                // variant="contained"
                // disableElevation
                title={'GO LIVE'}
                // className='mui-custom-button'
                onClick={() => {
                  startHLSStreaming();
                }}
              >{streamStateChangeInProgress ? <CircularProgress sx={{ mr: 1 }} size={20} /> : <PodcastsOutlined className='controls__button__live' />}</BeatButton>
            </div>
          }
        </>
        : <BeatButton

          title={'LEAVE ROOM'}
          // classes={customButtonClasses}
          // variant="contained"
          // disableElevation
          // className='leave'
          onClick={leaveRoom}
        >
          <LogoutOutlined sx={{ color: '#E58BE0' }} />
        </BeatButton>
      }
    </div >
  )
}

// Exporting the component for use in other parts of the application
export default Controls;
