import { CircularProgress } from '@mui/material';
import './BroadcasterList.scss';
import BroadcastListItem from '../BroadcastListItem/BroadcastListItem';

// Reading backend API URL from environment variables
const backendApiURL = process.env.REACT_APP_BACKEND_API_URL;

// Defining the BroadcastList component
function BroadcastList({ rooms, onRefresh }) {
    // If rooms data is not available, display a loading indicator
    if (!rooms)
        return <CircularProgress />

    // Rendering the list of broadcasting rooms
    return (
        <div className="room__list">
            {
                // Mapping each room to a BroadcastListItem component
                rooms.map((peer) => (
                    <BroadcastListItem peer={peer} key={peer.id} onRefresh={onRefresh} />
                ))
            }
        </div>
    )
}


export default BroadcastList;
