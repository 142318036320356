import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { HMSRoomProvider } from '@100mslive/react-sdk';

// // eslint-disable-next-line no-restricted-globals
// if (location.protocol === "http:" && !location.host.startsWith('localhost')) {
//   // eslint-disable-next-line no-restricted-globals
//   location.replace(`https:${location.href.substring(location.protocol.length)}`);

// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HMSRoomProvider>
    <App />
  </HMSRoomProvider>
);