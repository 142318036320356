import { useNavigate } from "react-router-dom";
import "./SignOut.scss";
import { useClerk } from "@clerk/clerk-react";

function SignOutBeat() {
    const { signOut } = useClerk();
    const navigate = useNavigate();

    return <button className="sign__out" onClick={async () => {
        await signOut();
        navigate('/sign-in/')
    }}> SIGN OUT</button>

}
export default SignOutBeat