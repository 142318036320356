// DownloadLink.js
import React from 'react';
import { Link } from '@mui/material';

function StreamListUrl({ assetId, createdAt, onDownload }) {
  return (
    <div className='room__url__links'>
      {new Date(createdAt).toLocaleString()}
      <Link onClick={() => onDownload(assetId)}>
        Download
      </Link>
    </div>
  );
}

export default StreamListUrl    ;
